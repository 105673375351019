import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {share} from 'rxjs/operators';
import {NgxSpinnerService} from 'ngx-spinner';
import {environment} from '../../../environments/environment';

const baseURL = environment.backend + environment.api + '/';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _token: string|null = null;

  public authFailure: EventEmitter<null> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private spinner: NgxSpinnerService
  ) {
    this._token = localStorage.getItem('tdl-token');
  }

  setToken(token: string|null) {
    this._token = token;
    if (token) {
      localStorage.setItem('tdl-token', token);
    } else {
      localStorage.removeItem('tdl-token');
    }
  }

  getRequestRaw(path: string): Observable<string> {
    this.spinner.show();
    return this._hideSpinner(this.silentGetRequestRaw(path));
  }

  silentGetRequestRaw(path: string): Observable<string> {
    return this._request<string>('GET', path, undefined, /* raw= */ true);
  }

  getRequest<Type>(path: string): Observable<Type> {
    this.spinner.show();
    return this._hideSpinner(this.silentGetRequest<Type>(path));
  }

  // Won't show or hide the spinner
  silentGetRequest<Type>(path: string): Observable<Type> {
    return this._request<Type>('GET', path);
  }

  postRequest<Type>(path: string, body?: Object): Observable<Type> {
    this.spinner.show();

    return this._hideSpinner(this._request<Type>('POST', path, body));
  }

  deleteRequest<Type>(path: string, body?: Object): Observable<Type> {
    this.spinner.show();
    return this._hideSpinner(this._request<Type>('DELETE', path, body));
  }

  private _request<Type>(
      method: string, path: string, body?: Object,
      raw: boolean = false): Observable<Type> {
    const headers = {};

    if (this._token) {
      headers['Authorization'] = 'Bearer ' + this._token;
    }

    if (body) {
      headers['Content-Type'] = 'application/json';
      if (typeof body == 'string') {
        body = JSON.stringify(body);
      }
    }

    let responseType: "text" | "json" = 'json';
    if (raw) responseType = 'text';

    const options = {
      headers,
      body,
      responseType,
    };

    // The share() operator keeps the request from being triggered again on
    // each subscription.
    const response = (this.httpClient.request(
        method,
        baseURL + path,
        options) as Observable<Type>).pipe(share());

    // Handle certain common error conditions at this level.
    response.subscribe(
        // Ignore success callback.
        () => {},
        response => {
          // Log all error responses.
          console.error('Error', response);

          // Special handling for HTTP 401 errors, which should wipe the user's
          // login status and send people back to the login screen (if they
          // aren't already there).
          if (response.status == 401) {
            this.authFailure.emit(null);
          }

          // If the backend crashes, there isn't an "error" object, which is
          // what the caller will look for to show error messages on screen.
          // To simplify error handling at the call sites, synthesize an error
          // object.
          if (!response.error?.title) {
            response.error = {
              title: 'Unknown server error!',
            };
          }
        });

    return response;
  }

  private _hideSpinner<Type>(response: Observable<Type>): Observable<Type> {
    // Hide the spinner on both success and failure.
    response.subscribe(response => {
      setTimeout(() => this.spinner.hide(), 500);
    }, response => {
      setTimeout(() => this.spinner.hide(), 500);
    });
    return response;
  }
}
