import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {LoginGuard} from './shared/guard';

// FIXME: Temporary redirect while we turn down the site
const routes: Routes = [
  {
    path: 'brb',
    loadChildren: () =>
      import('./brb/brb.module').then(m => m.BrbModule),
  },
  {
    path: '',
    redirectTo: '/brb',
    pathMatch: 'prefix',
  },
];
/*
const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./auth/login/login.module').then(m => m.LoginModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('./auth/verify/verify.module').then(m => m.VerifyModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./auth/forgot-password/forgot-password.module').then(
        m => m.ForgotPasswordModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'reset-password/:Email/:ResetToken',
    loadChildren: () =>
      import('./auth/reset-password/reset-password.module').then(
        m => m.ResetPasswordModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./register/register.module').then(
        m => m.RegisterModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'registration-confirmed',
    loadChildren: () =>
      import(
        './register/confirmed/confirmed.module'
      ).then(m => m.ConfirmedModule),
    canActivate: [LoginGuard],
  },
  {
    // For all other paths, delegate to the "layout" module.
    path: '',
    loadChildren: () =>
      import('./layout/layout.module').then(m => m.LayoutModule),
  },
];
*/

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
