import {Injectable} from '@angular/core';
import {UrlTree} from '@angular/router';
import {BaseGuard} from './base.guard';

// Only allows access to the route if the user is NOT logged in.
// Otherwise, forwards the user to the default route for their role.
// Used only on login pages, and other pages that only make sense when the user
// is not logged in.
@Injectable()
export class LoginGuard extends BaseGuard {
  onActivation(): boolean|UrlTree {
    const user = this.userService.getUser();
    if (user != null) {
      return this.redirect('/');
    }
    return true;
  }
}
