<div class="confirmation-modal">
  <div class="modal-header border-0 pb-0">
    <h4 class="modal-title">Are You Sure?</h4>
  </div>
  <div class="modal-body">
    <ng-container #container></ng-container>
  </div>
  <div class="modal-footer pb-4 pt-0 justify-content-center border-0">
    <button class="confirm-btn" (click)="onConfirm()">Confirm</button>
    <button class="cancel-btn" (click)="onCancel()">Cancel</button>
  </div>
</div>
