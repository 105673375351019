import {Injectable} from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class FeedbackRequiredGuard
    implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private toastr: ToastrService) {}

  canDeactivate(component: ComponentCanDeactivate): boolean {
    if (component.canDeactivate()) {
      this.toastr.clear();
      return true;
    } else {
      this.toastr.error('Please submit feedback before nagivating away.', '', {
        disableTimeOut: true,
      });
      return false;
    }
  }
}
