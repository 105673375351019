import {Injectable} from '@angular/core';
import {BaseGuard} from './base.guard';
import {UrlTree} from '@angular/router';

@Injectable()
export class DefaultRouteGuard extends BaseGuard {
  onActivation(): boolean|UrlTree {
    if (this.userService.isAdmin()) {
      return this.redirect('/manage-physicians');
    } else if (this.userService.isGroupManager()) {
      return this.redirect('/manage-groups');
    } else {
      return this.redirect('/colleague-connections');
    }
  }
}
