import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {
  AuthGuard,
  DefaultRouteGuard,
  FeedbackRequiredGuard,
  LoginGuard,
} from './shared/guard';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      timeOut: 5000,
      extendedTimeOut: 5000,
    }),
    NgxSpinnerModule,
    NgbModule,
  ],
  providers: [
    AuthGuard,
    DefaultRouteGuard,
    FeedbackRequiredGuard,
    LoginGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
