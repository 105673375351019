import {Component} from '@angular/core';
import {VERSION} from 'src/environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  readonly version = VERSION.raw;

  constructor() {
    // This will be not be visible to the user, but could be seen in a debugger
    // if we ever need to check the deployed frontend version.
    window['tdlFrontEndVersion'] = this.version;
  }

  ngOnInit() {}

  ngAfterViewInit() {}
}
