import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {ApiService} from './api.service';
import {ToastrService} from 'ngx-toastr';
import {BasicUser} from '../model';
import {Role} from 'src/app/shared/roles';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(
    private router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    const userData = localStorage.getItem('tdl-user');
    this._user = userData ? JSON.parse(userData) as BasicUser : null;
    this.apiService.authFailure.subscribe(() => this.onAuthFailure());
  }

  private _user: BasicUser|null;

  isAdmin() {
    return this._user?.Role == Role.Admin;
  }

  isGroupManager() {
    return this._user?.Role == Role.GroupManager;
  }

  isPhysician() {
    return this._user?.Role == Role.Physician;
  }

  isSubscribed() {
    return true;
    /* FIXME: restore this check
    return this._user?.IsSubscribed || this.isAdmin() || this.isGroupManager();
    */
  }

  getUser(): BasicUser|null {
    return this._user;
  }

  setUser(user: BasicUser|null) {
    this._user = user;
    if (user) {
      localStorage.setItem('tdl-user', JSON.stringify(user));
    } else {
      localStorage.removeItem('tdl-user');
    }
  }

  private onAuthFailure() {
    // If an auth failure is returned in any logged-in state, log out and
    // return to the login screen.
    if (this._user) {
      this.setUser(null);
      this.router.navigate(['/login']);
      this.modalService.dismissAll();
      this.toastr.error('Login expired!', '', {
        disableTimeOut: true,
      });
    }
  }

  updateUserStatus(silent: boolean): Observable<BasicUser> {
    let request: Observable<BasicUser>;
    if (silent) {
      request = this.apiService.silentGetRequest<BasicUser>('user/status');
    } else {
      request = this.apiService.getRequest<BasicUser>('user/status');
    }

    request.subscribe(
        user => {
          // Update user status.
          if (this._user) {
            if (!user.IsActive) {
              // The user was deactivated.  Log them out!
              this.onAuthFailure();
              return;
            }

            const oldSubscribed = this._user.IsSubscribed;
            const newSubscribed = user.IsSubscribed;

            // Update this object in memory and in storage.
            this._user = user;
            this.setUser(this._user);

            if (oldSubscribed && !newSubscribed) {
              // Subscription expired or cancelled!
              // Make the user subscribe again.
              this.router.navigate(['/']);
            }
          }
        },
        // Only use the generic error handler in ApiService.
        () => {});

    return request;
  }

  insertFeedbackRequest(eventType: string, eventID: string) {
    if (this._user) {
      const feedbackID = eventType + ':' + eventID;
      if (!this._user.NeedsToLeaveFeedback.includes(feedbackID)) {
        this._user.NeedsToLeaveFeedback.push(feedbackID);
      }
    }
  }

  shouldRedirect(currentUrl: string): string|null {
    if (this._user == null) {
      // Not logged in.
      return null;
    }

    if (this.isSubscribed() == false &&
        !currentUrl.startsWith('/subscription-plan')) {
      return '/subscription-plan';
    }

    if (this._user.NeedsToLeaveFeedback.length == 0) {
      // No feedback required.
      return null;
    }

    // No feedback required if we are in a survey.
    if (currentUrl.startsWith('/survey')) {
      return null;
    }

    // No feedback required if we are in a webinar.
    if (currentUrl.startsWith('/webinars/join')) {
      return null;
    }

    // No feedback required if we are in a meeting.
    if (currentUrl.startsWith('/colleague-connections/meeting')) {
      return null;
    }

    const feedbackID = this._user.NeedsToLeaveFeedback[0];
    const [eventType, eventID] = feedbackID.split(':');
    // Feedback required at this path.
    return `/survey/${eventType}/${eventID}`;
  }
}
