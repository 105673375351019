<!-- This will be not be visible to the user, but could be seen in a debugger
     if we ever need to check the deployed frontend version. -->
<noscript>Frontend version {{ version }}</noscript>

<ngx-spinner bdColor="rgba(0,0,0,0.5)" size="medium" color="#fff" type="ball-beat" [fullScreen]="true">
  <p style="color: white;font-size: 18px;">Loading...</p>
</ngx-spinner>
<!-- Preload the necessary fonts to avoid a flash of unstyled content. -->
<span
  style="position: absolute; opacity: 0;"
  class="material-icons-outlined">&nbsp;</span>
<span
  style="position: absolute; opacity: 0;"
  class="fa">&nbsp;</span>
<router-outlet></router-outlet>
