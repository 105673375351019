import {Injectable} from '@angular/core';
import {BaseGuard} from './base.guard';
import {UrlTree} from '@angular/router';

@Injectable()
export class AuthGuard extends BaseGuard {
  onActivation(): boolean|UrlTree {
    const roles = this.getData<string[]>('roles');
    if (!roles) {
      console.error('Failed to get allowed roles from route or its parents!');
      return false;
    }

    const user = this.userService.getUser();
    if (user == null) {
      // Not logged in?  Back to login, with query params for context to return
      // here after login.
      return this.redirect('/login', {'return': this.oldPath});
    }

    if (!roles.includes(user.Role)) {
      // Wrong role?  Back to root.
      return this.redirect('/');
    }

    if (this.userService.isAdmin()) {
      // Admin?  Right this way.
      return true;
    }

    if (!this.userService.isSubscribed()) {
      // Not subscribed?  Pick a plan.
      return this.redirect('/subscription-plan');
    }

    const newPath = this.userService.shouldRedirect(this.oldPath);
    if (newPath) {
      // The user needs to leave webinar or meeting feedback, or needs to
      // resubscribe.
      return this.redirect(newPath);
    }

    // Otherwise, they may activate the route.
    return true;
  }
}
