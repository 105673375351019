import {Injectable, TemplateRef} from '@angular/core';
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmationComponent} from 'src/app/shared/form/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private modalService: NgbModal) {}

  confirm(template: TemplateRef<any>,
          confirmCallback: (() => void),
          cancelCallback: (() => void)): void {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-window',
    };

    const modalRef: NgbModalRef = this.modalService.open(
        ConfirmationComponent,
        ngbModalOptions);

    const instance = modalRef.componentInstance as ConfirmationComponent;
    instance.bodyTemplate = template,
    instance.confirmCallback = confirmCallback;
    instance.cancelCallback = cancelCallback;
  }
}
