import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';
import {UserService} from '../services';

interface StringMap { [key: string]: string; }

@Injectable()
export abstract class BaseGuard implements CanActivate, CanActivateChild {
  private _route: ActivatedRouteSnapshot|null = null;

  constructor(
    protected router: Router,
    protected userService: UserService
  ) {}

  protected redirect(newPath: string,
                     queryParams: StringMap|null = null): UrlTree|boolean {
    const name = this.constructor.name;
    if (newPath != this.oldPath) {
      console.log(`${name} redirecting from ${this.oldPath} to ${newPath}`);

      var options: {queryParams: StringMap}|undefined;
      if (queryParams) {
        options = {queryParams};
      }

      return this.router.createUrlTree([newPath], options);
    }
    return true;
  }

  protected getData<T>(name: string): T|null {
    // Inherit this data from parents if needed.
    let data: T = this._route!.data[name] as T;
    let currentRoute: ActivatedRouteSnapshot | null = this._route;
    while (data == null && currentRoute && currentRoute != this._route!.root) {
      data = currentRoute.data[name] as T;
      currentRoute = currentRoute.parent;
    }
    return data;
  }

  abstract onActivation(): boolean|UrlTree;

  canActivate(route: ActivatedRouteSnapshot): boolean|UrlTree {
    this._route = route;
    return this.onActivation();
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean|UrlTree {
    this._route = route;
    return this.onActivation();
  }

  protected get oldPath(): string {
    // See https://stackoverflow.com/a/53429547
    return this._route!.pathFromRoot
        .map(v => v.url.map(segment => segment.toString()).join('/'))
        .join('/')
        // Sometimes we get extra slashes, which throw off comparisons.
        .replace(/\/{2,}/g, "/");
  }
}
