import {
  AfterViewInit,
  Component,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {delay} from 'src/app/shared/delay';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements AfterViewInit {
  @ViewChild('container', {read: ViewContainerRef})
  container!: ViewContainerRef;

  public bodyTemplate: TemplateRef<any>|null = null;

  public confirmCallback: (() => void)|null = null;
  public cancelCallback: (() => void)|null = null;

  constructor(private modalService: NgbModal) {}

  ngAfterViewInit(): void {
    delay(() => {
      this.container.createEmbeddedView(this.bodyTemplate!);
    });
  }

  onConfirm() {
    if (this.confirmCallback) {
      this.confirmCallback();
    }

    this.confirmCallback = null;
    this.cancelCallback = null;
    this.modalService.dismissAll();
  }

  onCancel() {
    if (this.cancelCallback) {
      this.cancelCallback();
    }

    this.confirmCallback = null;
    this.cancelCallback = null;
    this.modalService.dismissAll();
  }
}
